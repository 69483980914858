import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { DeeploopOcta, ArrowLeft } from "../components/icons"

import { cx } from "emotion"
import { Space, Grid, Background, Flex, SiteColors } from "../styles"

import { Row, Col } from "reactstrap"

import SignUp from '../components/forms/signup'

const SecondPage = () => (
  <Layout headerType={"none"} hasFooter={false} hasCta={false}>
    <SEO title="Sign up" />
    <Row style={{
        position:"absolute",
        height:"100%",
        width:"100%",
        top:0,
        left:0,
        right:0,
        bottom:0,
        margin:0
      }}>
      <Col xs={12} sm={6} className={cx(Background.grey, Flex.hcc)} 
        style={{
          height:"100%",
          position:"relative"
        }}>
        <div style={{position:"absolute",top:15,left:15}}>
          <Link to="/">
            <DeeploopOcta color={SiteColors.grey.text} style={{height:40,width:40}} />
          </Link>
        </div>
        <h3 style={{width:"60%"}}>Double your success, while working less</h3>
      </Col>
      <Col xs={12} sm={6} className={cx(Flex.vcc)} style={{height:"100%"}}>
        <SignUp />
      </Col>
    </Row>
  </Layout>
)

export default SecondPage
